import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import PageHeader from "../components/PageHeader"
import { Main, Container } from "../components/Elements"

const KeyPrinciplesPage = () => {
  const { heroImage } = useStaticQuery(graphql`
    query KeyPrinciplesPageQuery {
      heroImage: file(relativePath: { eq: "hero/key-principles.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 60)
        }
      }
    }
  `)

  const heroImageData = getImage(heroImage)

  return (
    <Main>
      <Seo
        title="Key Principles | The road to recovery is paved with discovery."
        desc="What makes us better than other sports massage therapists? Client centred approach. Understanding no two bodies are alike and the goals of the client dictate what type of massage they receive. Healthy relationships and honest dialogue with customers. Professional standards and always acting in the best interest of the client. To deliver the best service on the market and offer value for money. "
      />

      <PageHeader
        image={heroImageData}
        title="Key Principles"
        text="The road to recovery is paved with discovery"
      />

      <Container>
        <div className="pb-16 grid grid-cols-3 gap-y-6 gap-x-10">
          <div className="prose max-w-none col-span-3 lg:col-span-2">
            <p>
              The name Ascension came from our expectation of what a sport
              massage should be… Better than what is currently out there. One
              size fits all treatments do not work, as each client has their own
              perspective and goals on what treatment they should receive. A
              client centred approach allows for us to take the time to
              understand and get to know you. This allows for open dialogue and
              offering a service that best represents your needs. Our aim is to
              provide a premium service which puts you at the centre of all
              decisions giving you control of your own treatment, ensuring
              treatment is meaningful and the outcome is desirable.
            </p>
            <ul className="">
              <li>Client centred approach.</li>
              <li>
                Understanding no two bodies are the same and the goals of the
                client dictate what type of treatment is delivered.
              </li>
              <li>
                Building healthy relationships with customers is crucial in
                allowing an open and honest dialogue. We encourage feedback at
                any time, so the current and any future treatments can be
                adapted to the client’s needs.
              </li>
              <li>
                Always acting professionally and ethically looking out for the
                best interest of the client.
              </li>
              <li>
                To deliver the best service on the market, and offer the
                greatest value for money.
              </li>
            </ul>
          </div>
          <div className="max-w-none col-span-3 lg:col-span-1">
            <figure className="text-center">
              <StaticImage
                src="../assets/images/car-donnelly-2024-key-principles.jpg"
                quality={90}
                width={700}
                height={933}
                placeholder="blurred"
                title="Carl Donnelly, Founder and registered Physiotherapist"
                alt="Carl Donnelly, Founder and registered Physiotherapist"
                className="transition duration-500 ease transform hover:scale-110"
              />
              <figcaption className="mt-2 text-sm text-gray-600">
                Carl Donnelly, Founder and registered Physiotherapist
              </figcaption>
            </figure>
          </div>
        </div>
      </Container>
    </Main>
  )
}

export default KeyPrinciplesPage
